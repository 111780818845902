import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Element, TabGroupWithContent } from '@americanexpress/aqx-components';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';
import stylesLayout from './__styles__/index.scss?modules';
import styles from './__styles__/tabControl.scss?modules';

import TabContent from './TabContent';
import { itemContentType } from './types';

let instNum = 0;
const getInstId = () => {
  instNum = instNum + 1;
  return `instCm${instNum}`;
};

const buildTabItems = (contentList, instId) => {
  return (contentList || []).map((item, i) => {
    return {
      Component: ({ isActive }) => (
        <Element className={clsx(styles.tabHeader, { [styles.selected]: isActive })}>
          {item.title}
        </Element>
      ),
      tabId: `tab-${instId}-${i}`,
      qeId: instId
    };
  });
};

const TabControl = ({ contentList, onTabClick, ...otherProps }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const instId = getInstId();
  const tabProps = {
    tabItems: buildTabItems(contentList, instId),
    data: (contentList || []).map((item, i) => {
      const props = { content: item, index: i, activeIndex, ...otherProps };
      return {
        content: <TabContent {...props} />,
        id: `tabpanel-TabGroupWithContent-${instId}-${i}`,
        ariaLabelledBy: `tab-${instId}-${i}`,
        qeId: instId
      };
    }),
    onTabIndexChange: selectedIndex => {
      setActiveIndex(selectedIndex);
      const { dtmEvents } = contentList[selectedIndex] || {};
      const eventList = Array.isArray(dtmEvents) ? dtmEvents : [];
      const dtmClick =
        eventList.find(dtm => String(dtm.eventAction).toLowerCase() === 'click') ||
        {};
      sendDTMClickEvent(dtmClick);
      onTabClick(selectedIndex);
    },
    shouldStayHorizontal: true,
    qeId: instId
  };

  return (
    <Element className={clsx(styles.tabControl, stylesLayout.tabOuter)}>
      <TabGroupWithContent {...tabProps} />
    </Element>
  );
};

TabControl.propTypes = {
  contentList: PropTypes.arrayOf(itemContentType)
};

TabControl.defaultProps = {
  qeId: 'GcsVactabbedHero',
  contentList: []
};

export default React.memo(TabControl);
