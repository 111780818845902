import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';
import isProduction from 'src/universal/utils/isProduction';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';

export const getFormattedLinks = (links, pageEep, eepHash) => {
  return (links || []).map(link => {
    const cardEep = eepHash[link.type || ''];
    const eepVal = cardEep || pageEep;
    const { url, dtm } = link;
    const amexHost = isProduction()
      ? 'www.americanexpress.com'
      : 'qwww.americanexpress.com';
    const url2 = replaceStringWithVars({
      varsObject: {
        amexHost: amexHost,
        eep: eepVal
      },
      string: url
    });
    return {
      ...link,
      url: mapUrlWithDtmLinkQuery(url2, dtm)
    };
  });
};

export const isContentWrappedByBTag = content => {
  if (!content || typeof content !== 'string') {
    return false;
  }
  const contentVal = content.toLowerCase();
  return contentVal.startsWith('<b>') && contentVal.endsWith('</b>');
};
