import { Color, FontType, Align } from '@americanexpress/aqx-components';

const commonTitleTheme = {
  color: Color.White,
  textAlign: Align.Left,
  marginBottom: 15,
  fontType: FontType.HelveticaBold
};
const title2Theme = {
  ...commonTitleTheme,
  fontType: FontType.BentonBold
};

const tabContentTitleTheme = {
  ...commonTitleTheme,
  color: Color.DeepBlue,
  fontType: FontType.BentonBold
};

const tabContentTextTheme = {
  ...tabContentTitleTheme,
  color: Color.Gray06,
  fontType: FontType.HelveticaRegular
};

const userCardSectionTitleTheme = {
  color: Color.DeepBlue,
  textAlign: Align.Center,
  fontType: FontType.HelveticaRegular,
  marginBottom: 15
};

const userCardTitleTheme = {
  textAlign: Align.Left,
  fontType: FontType.HelveticaRegular,
  color: Color.DeepBlue
};

const tabContentPanelTheme = {
  marginRight: 15
};

const userCardBoxTheme = {
  bgColor: Color.Gray02
};

const defaultHeroCustomization = {
  heroTitle1: {
    htmlTag: 'h3',
    fontSizes: {
      sm: 16,
      md: 16,
      lg: 16
    },
    themes: {
      sm: commonTitleTheme,
      md: commonTitleTheme,
      lg: commonTitleTheme
    }
  },
  heroTitle2: {
    htmlTag: 'div',
    fontSizes: {
      sm: 20,
      md: 20,
      lg: 20
    },
    themes: {
      sm: title2Theme,
      md: title2Theme,
      lg: title2Theme
    }
  }
};

const defaultTabContentCustomization = {
  contentPanel: {
    themes: {
      sm: { marginTop: 30 },
      md: tabContentPanelTheme,
      lg: tabContentPanelTheme
    }
  },
  tabContentTitle: {
    htmlTag: 'div',
    fontSizes: {
      sm: 18,
      md: 20,
      lg: 20
    },
    themes: {
      sm: tabContentTitleTheme,
      md: tabContentTitleTheme,
      lg: tabContentTitleTheme
    }
  },
  tabContentText: {
    htmlTag: 'div',
    fontSizes: {
      sm: 15,
      md: 16,
      lg: 16
    },
    themes: {
      sm: tabContentTextTheme,
      md: tabContentTextTheme,
      lg: tabContentTextTheme
    }
  },
  learnMoreCta: {
    themes: {
      sm: { marginBottom: 15 },
      md: { marginRight: 10 },
      lg: { marginRight: 10 }
    }
  }
};

const defaultUserCardBoxCustomization = {
  userCardBox: {
    themes: {
      sm: userCardBoxTheme,
      md: userCardBoxTheme,
      lg: userCardBoxTheme
    }
  },
  userCardSectionTitle: {
    htmlTag: 'div',
    fontSizes: {
      sm: 20,
      md: 20,
      lg: 20
    },
    themes: {
      sm: userCardSectionTitleTheme,
      md: { ...userCardSectionTitleTheme, marginBottom: 15 },
      lg: { ...userCardSectionTitleTheme, marginBottom: 15 }
    }
  },
  userCardTitle: {
    htmlTag: 'div',
    fontSizes: {
      sm: 20,
      md: 20,
      lg: 20
    },
    themes: {
      sm: userCardTitleTheme,
      md: userCardTitleTheme,
      lg: userCardTitleTheme
    }
  }
};

export {
  defaultHeroCustomization,
  defaultTabContentCustomization,
  defaultUserCardBoxCustomization
};
