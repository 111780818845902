import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { Breakpoint, MatchHeightProvider } from '@americanexpress/aqx-components';
import { ReinforcementBanner as ReinforcementBannerACQ } from 'acq-components/lib/composite-components/HeroBanner/variants/us-commercial/ReinforcementBanner';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import MGMWidget from 'src/universal/components/MGMWidget';
import BannerSide from './BannerSide';
import 'src/universal/components/MGMWidget/__styles__/heroWidgetStyles.scss';

import {
  mapUrlWithDtmLinkQuery,
  sendDTMClickEvent
} from 'service_modules/models/dtm-tagging';
import svgFetcherWithIdAttrRemoval from 'acq-components/lib/utils/svgFetcherWithIdAttrRemoval';

import pageStyles from './__styles__/reinforcementBanner.scss?modules';
import { isLoggedInGCS } from 'src/universal/redux/userType';
import { isCardMemberCardsLoaded } from 'src/universal/redux/cardMemberInfo';
import { didWidgetFail } from 'src/universal/redux/mgmWidget';

const buildAcqProps = ({
  title,
  description,
  buttonOnClick,
  buttonChildren,
  svgName,
  svgDirectory,
  styles,
  ariaLabel,
  imageAlt
}) => {
  const svgProp = {
    onLoad: (err, svg) => {
      if (err) {
        return;
      }
      const labelFromTitle = (title || '').split(',')[0];
      const labelValToUse = imageAlt ? imageAlt : labelFromTitle;
      const titleEle = svg.getElementsByTagName('title');
      if (titleEle && titleEle.length) {
        svg.removeChild(titleEle[0]);
      }
      svg.setAttribute('aria-label', labelValToUse);
      svg.setAttribute('alt', labelValToUse);
    }
  };
  return {
    Component: 'div',
    className: styles.sideContainer,
    titleProps: {
      className: styles.sideText,
      components: {
        title
      },
      styles: {
        title: styles.sideTextTitle
      },
      ChildComponent: 'h2'
    },
    textProps: {
      className: styles.sideText,
      components: {
        description
      },
      styles: {
        description: styles.sideTextDescription
      },
      ChildComponent: 'div'
    },
    buttonProps: {
      onClick: buttonOnClick,
      className: styles.sideButton,
      children: buttonChildren,
      'aria-label': ariaLabel
    },
    iconProps: {
      Svg: svgFetcherWithIdAttrRemoval(svgName, svgDirectory, svgProp),
      className: styles.sideIcon
    }
  };
};

const mapGraphQLToAcqProps = (graphQLData, styles) =>
  graphQLData.reduce(
    (
      acc,
      {
        header: title,
        imageName: svgName,
        desc,
        buttons: [{ title: ButtonTitle, url, dtm, ariaLabel }],
        imageAlt
      }
    ) =>
      acc.concat(
        buildAcqProps({
          title,
          description: get(desc, '[0].text'),
          buttonOnClick: () => {
            sendDTMClickEvent(dtm);
            setTimeout(() => {
              window.open(mapUrlWithDtmLinkQuery(url, dtm), '_blank');
            }, 500);
          },
          buttonChildren: ButtonTitle,
          svgName,
          svgDirectory: svgName.split('-')[0] === 'dls' ? 'dls' : 'us-commercial',
          styles,
          ariaLabel,
          imageAlt
        })
      ),
    []
  );

const RABBanner = React.memo(
  ({
    content,
    isLoggedIn,
    didWidgetFail,
    isCardMemberCardsLoaded,
    locale,
    styles
  }) => {
    const [primaryContentProps, secondaryContentProps] = mapGraphQLToAcqProps(
      content,
      styles
    );

    const widgetProps = {
      locale,
      onReferNowClick: () => {
        const referData = Array.isArray(content) ? content[1] : null;
        const dtmObj = Array.isArray(referData?.buttons)
          ? referData.buttons[0]?.dtm
          : {};
        sendDTMClickEvent(dtmObj);
      }
    };

    const bannerProps =
      isLoggedIn && isCardMemberCardsLoaded && !didWidgetFail
        ? {
            styles: styles,
            primaryContentProps: primaryContentProps,
            SecondaryContentComponent: MGMWidget,
            secondaryContentProps: widgetProps
          }
        : {
            styles: styles,
            primaryContentProps: primaryContentProps,
            secondaryContentProps: secondaryContentProps
          };

    return (
      <LazyScrollMetricTracker offset={100}>
        <div className={pageStyles.wrapper}>
          <Breakpoint>
            {({ sm }) => {
              if (
                !sm &&
                (!isLoggedIn || !isCardMemberCardsLoaded || didWidgetFail)
              ) {
                const propsToUse = {
                  styles: styles,
                  PrimaryContentComponent: BannerSide,
                  primaryContentProps: primaryContentProps,
                  SecondaryContentComponent: BannerSide,
                  secondaryContentProps: secondaryContentProps
                };

                return (
                  <MatchHeightProvider isResponsive={true}>
                    <ReinforcementBannerACQ {...propsToUse} />
                  </MatchHeightProvider>
                );
              }

              return <ReinforcementBannerACQ {...bannerProps} />;
            }}
          </Breakpoint>
        </div>
      </LazyScrollMetricTracker>
    );
  }
);

RABBanner.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      imageName: PropTypes.string,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
          dtm: PropTypes.object
        })
      ),
      desc: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string
        })
      )
    })
  )
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedInGCS(state),
  isCardMemberCardsLoaded: isCardMemberCardsLoaded(state),
  didWidgetFail: didWidgetFail(state)
});

export default connect(mapStateToProps)(RABBanner);
