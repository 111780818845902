/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import isProduction from 'src/universal/utils/isProduction';
import { connect } from 'react-redux';
import { isLoggedInGCS } from 'src/universal/redux/userType';
import {
  getCardMemberCards,
  isCardMemberCardsEmpty,
  isCardMemberCardsLoaded
} from 'src/universal/redux/cardMemberInfo';
import {
  dispatchMGMWidgetError,
  dispatchMGMWidgetReset,
  dispatchMGMWidgetLoad,
  didWidgetLoad
} from 'src/universal/redux/mgmWidget';
import logger from 'src/universal/utils/IsomorphicLogger';
import clsx from 'clsx';
import './__styles__/widgetGlobal.scss';

const formatMsg = (msg = {}) => {
  if (typeof msg === 'string') {
    return msg;
  }
  const msgVal = typeof msg === 'object' ? msg : {};
  return Object.keys(msgVal)
    .map(k => {
      const val = msg[k];
      return `${k}:${typeof val === 'string' ? val : ''}`;
    })
    .join('|');
};

// we need the visibility into this external element due to its nature of uncertainty.
const clientLog = (header, msg) => {
  console.log(header, msg);
};

let eventListenerAdded = false;

const MGMWidget = ({
  isLoggedIn,
  isCardMemberCardsLoaded,
  cardMemberCards,
  setWidgetError,
  resetWidgetError,
  setWidgetSuccess,
  didWidgetLoad,
  locale = 'cardDetail',
  divID = 'mgmrefer',
  shortName = '',
  onClick = () => {},
  onReferNowClick
}) => {
  const EVTC = 'mgm-widget';
  const [widgetMounted, setWidgetMounted] = useState(false);
  const keyInfo = useRef({});

  const axpOnReferralWidgetError = (err = 'No error message provided from MGM') => {
    const header = `${EVTC}-error`;
    const infoToLog = `${formatMsg(err)}|${formatMsg(keyInfo.current)}`;
    logger(header, { message: infoToLog });
    clientLog(header, infoToLog);
    setWidgetError();
  };

  const axpOnReferralWidgetSuccess = (msg = 'widget-success') => {
    const header = `${EVTC}-success`;
    const infoToLog = `${formatMsg(msg)}|${formatMsg(keyInfo.current)}`;
    logger(header, { message: infoToLog });
    clientLog(header, infoToLog);
    setWidgetSuccess();
  };

  let jsScriptSrc;
  let cssScriptSrc;
  let consumerKey;

  if (isProduction()) {
    jsScriptSrc = 'https://mgmrefer.americanexpress.com/js/main.js';
    cssScriptSrc = 'https://mgmrefer.americanexpress.com/css/main.css';
    consumerKey = 'rKkkWTBi1jCfdJbaA31F2LCHaTN83j6q';
  } else {
    jsScriptSrc = 'https://mgmrefer-qa.americanexpress.com/js/main.js';
    cssScriptSrc = 'https://mgmrefer-qa.americanexpress.com/css/main.css';
    consumerKey = 'mPL2fEKGbyzSdzvTEQUGbaeNs9caAl5T';
  }

  useEffect(
    () => {
      if (isLoggedIn) {
        const createErrHandler = (targetUrl) => {
          return () => {
            const header = `${EVTC}-error`;
            const infoToLog = `Failed loading ${targetUrl}`;
            logger(header, { message: infoToLog });
            clientLog(header, infoToLog);
            setWidgetError();
          };
        };
        resetWidgetError();
        const jsScript = document.createElement('script');
        const cssScript = document.createElement('link');
        jsScript.src = jsScriptSrc;
        jsScript.type = 'text/javascript';
        cssScript.href = cssScriptSrc;
        cssScript.rel = 'stylesheet';
        jsScript.onload = () => {
          setWidgetMounted(true);
        };
        jsScript.onerror = createErrHandler(jsScriptSrc);
        cssScript.onerror = createErrHandler(cssScriptSrc);
        document.body.appendChild(cssScript);
        document.body.appendChild(jsScript);
        window.axpOnReferralWidgetError = axpOnReferralWidgetError;
        window.axpOnReferralWidgetSuccess = axpOnReferralWidgetSuccess;
      }
    },
    [isLoggedIn]
  );

  useEffect(
    () => {
      if (widgetMounted && isCardMemberCardsLoaded) {
        let token = 'blueboxvalues';

        if (shortName) {
          const isUserCard = cardMemberCards.find(
            card => card.shortName === shortName
          );
          if (!isUserCard) {
            token = '';
          }
        }

        const cardVals = (Array.isArray(cardMemberCards) ? cardMemberCards : [])
          .map(c => c.shortName)
          .join(',');
        keyInfo.current = {
          divID,
          token,
          consumerKey,
          locale,
          shortName,
          cards: cardVals
        };
        mgmReferWidget(divID, 'en_US', token, consumerKey, 'gcsshop', locale);
      }
    },
    [widgetMounted, isCardMemberCardsEmpty]
  );

  const getModalCloseEle = useCallback(() => {
    return document.querySelector(`#${divID} .modal .modalClose`);
  }, [divID]);

  const getBtnEle = useCallback(() => {
    return document.querySelector(`#${divID} #refer-now`);
  }, [divID]);

  const modalCloseClick = () => {
    const classList = document?.body?.classList;
    if (typeof classList?.remove === 'function') {
      classList.remove('mgmModalOpen');
    }
  };

  const btnClickHandler = () => {
    const classList = document?.body?.classList;
    if (typeof classList?.add === 'function') {
      classList.add('mgmModalOpen');
    }

    setTimeout(() => {
      const modalCloseCta = getModalCloseEle();
      if (typeof modalCloseCta?.addEventListener === 'function') {
        modalCloseCta.addEventListener('click', modalCloseClick);
      }
    }, 100);

    if (typeof onReferNowClick === 'function') {
      onReferNowClick();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const btnEle = getBtnEle();
      if (!eventListenerAdded && btnEle && didWidgetLoad) {
        btnEle.addEventListener('click', btnClickHandler);
        eventListenerAdded = true;
      }
    }, 500);

    return () => {
      const btnEle = getBtnEle();
      if (eventListenerAdded && btnEle) {
        btnEle.removeEventListener('click', btnClickHandler); 

        const closeBtn = getModalCloseEle();
        if (typeof closeBtn?.removeEventListener === 'function') {
          closeBtn.removeEventListener('click', modalCloseClick);
        }
      }
    };
  }, [didWidgetLoad]);

  return (
    <div
      id={divID}
      className={clsx({
        widgetLoaded: didWidgetLoad,
        needToRender: didWidgetLoad
      })}
      onClick={onClick}
    />
  );
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedInGCS(state),
  isCardMemberCardsLoaded: isCardMemberCardsLoaded(state),
  cardMemberCards: getCardMemberCards(state),
  didWidgetLoad: didWidgetLoad(state)
});

const mapDispatchToProps = dispatch => ({
  setWidgetError: () => dispatchMGMWidgetError(dispatch),
  resetWidgetError: () => dispatchMGMWidgetReset(dispatch),
  setWidgetSuccess: () => dispatchMGMWidgetLoad(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MGMWidget);
