import React from 'react';
import ProgressCircle from 'core_modules/ajax-loaders/templates/progress-circle/progress-circle';
import clsx from 'clsx';
import styles from './__styles__/index.scss?modules';

const WaitingScreen = ({ ariaLabelText = '' }) => {
  return (
    <div className={clsx(styles.tabbedHeroWrap, styles.spinner)}>
      <ProgressCircle ariaLabelText={ariaLabelText} />
    </div>
  );
};

export default React.memo(WaitingScreen);
