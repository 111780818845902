import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Element } from '@americanexpress/aqx-components';
import styles from './__styles__/index.scss?modules';
import TabControl from './TabControl';
import { getContentGroup, needToRenderCardBox } from './utils/index.js';
import UserCardBox from './UserCardBox';
import { heroDataType } from './types';

const DesktopAndTablet = ({
  data,
  isGCS,
  userCardsInfo,
  selectedCard,
  ...otherProps
}) => {
  const needUserCardBox = needToRenderCardBox(isGCS, userCardsInfo);
  const contentGrp = getContentGroup(data, selectedCard) || {};

  let cardBox = null;
  if (needUserCardBox) {
    const userCardBoxData = data?.userCardBoxData;
    const props = {
      userCardsInfo,
      selectedCard,
      userCardBoxData,
      layoutStyles: styles
    };
    cardBox = <UserCardBox {...props} />;
  }

  return (
    <Element
      className={clsx(styles.deskTopAndTabletWrap, {
        [styles.hasUserCard]: needUserCardBox
      })}
    >
      {cardBox}
      <TabControl
        contentList={contentGrp.content}
        cardArt={get(data, 'heroData.backgroundImage')}
        {...otherProps}
      />
    </Element>
  );
};

DesktopAndTablet.propTypes = {
  data: PropTypes.shape({
    heroData: heroDataType
  }),
  isGCS: PropTypes.bool,
  userCardsInfo: PropTypes.object
};

DesktopAndTablet.defaultProps = {
  data: {}
};

export default React.memo(DesktopAndTablet);
