import React, { useEffect, useState, useRef } from 'react';

// components
import { IconButton, Size, Color } from '@americanexpress/aqx-components';

// utilities
import clsx from 'clsx';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';

// styles
import styles from './__styles__/carouselCardTabs.scss?modules';

const CarouselCardTabs = ({ cardList, dtm, cardClick }) => {
  const [toggledCard, setToggledCard] = useState(0);
  const [showLeftToggle, setShowLeftToggle] = useState(false);
  const [showRightToggle, setShowRightToggle] = useState(cardList.length > 2);

  const scrollableElementRef = useRef(null);
  const cardButtonsRef = useRef([]);

  const scroll = scrollOffset => {
    const newScroll = scrollableElementRef.current.scrollLeft + scrollOffset;
    if (newScroll >= 0) {
      scrollableElementRef.current.scrollLeft = newScroll;
    } else {
      scrollableElementRef.current.scrollLeft = 0;
    }
  };

  const cardPropsList = (cardList || []).map(c => {
    return {
      cardUrl: c.src,
      header: { title: c.title },
      url: c.url,
      iaCode: c.iaCode
    };
  });

  const onItemClick = index => {
    const cardObj = (cardList || [])[index];
    const rawDtm = (dtm || []).find(d => d.key === 'secondaryCardArt');
    const cardDtm = {
      ...rawDtm,
      eventAsset: replaceStringWithVars({
        varsObject: { product: cardObj.shortName },
        string: rawDtm.eventAsset
      })
    };
    if (typeof cardClick === 'function') {
      cardClick(index, (cardList || [])[index], cardDtm);
    }
  };

  const onPaddleClick = direction => {
    const dtmObj = (dtm || []).find(d => d.key === `${direction}Scroll`);
    sendDTMClickEvent(dtmObj);
  };

  const cardClickHandler = i => {
    cardButtonsRef.current[i].focus();
    setToggledCard(i);
    onItemClick(i);
  };

  const paddleClickHandler = (scrollOffset, direction) => {
    scroll(scrollOffset);
    onPaddleClick(direction);
  };

  useEffect(
    () => {
      if (cardList.length < 3 || cardButtonsRef.current.length < 3) return;

      const options = {
        root: scrollableElementRef.current,
        threshold: 1,
        rootMargin: '0px'
      };

      const callback = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.ariaHidden = false;
          } else {
            entry.target.ariaHidden = true;
          }

          if (entry.target === cardButtonsRef.current[0]) {
            if (entry.isIntersecting) {
              setShowLeftToggle(false);
            } else if (!entry.isIntersecting) {
              setShowLeftToggle(true);
            }
            return;
          }

          if (
            entry.target ===
            cardButtonsRef.current[cardButtonsRef.current.length - 1]
          ) {
            if (entry.isIntersecting) {
              setShowRightToggle(false);
            } else if (!entry.isIntersecting) {
              setShowRightToggle(true);
            }
            return;
          }
        });
      };

      const cardButtonObserver = new IntersectionObserver(callback, options);
      cardButtonsRef.current.forEach(button => cardButtonObserver.observe(button));
    },
    [cardList.length]
  );

  return (
    <div className={styles.cardTabsStrip} ref={scrollableElementRef}>
      <IconButton
        directory="dls"
        onClick={() => paddleClickHandler(-80, 'left')}
        imageName="dls-icon-left"
        aria-label="Scroll left to view more cards"
        aria-hidden={!showLeftToggle}
        className={styles.leftPaddle}
        style={{
          visibility: showLeftToggle ? 'visible' : 'hidden',
          display: cardList.length > 2 ? 'block' : 'none'
        }}
        $size={Size.MD}
        theme={{
          color: Color.DeepBlue
        }}
      />
      {cardPropsList.map((eachCard, i) => {
        return (
          <button
            className={clsx({
              [styles.cardButton]: true,
              [styles.toggledButton]: toggledCard === i,
              [styles.notToggledButton]: toggledCard !== i,
              [styles.firstButton]: i === 0,
              [styles.lastButton]: i === cardList.length - 1
            })}
            onClick={() => cardClickHandler(i)}
            key={i}
            aria-label={`View ${eachCard?.header?.title}`}
            ref={el => (cardButtonsRef.current[i] = el)}
          >
            <img
              src={eachCard.cardUrl}
              alt=""
              aria-hidden={true}
              width="380px"
              height="240px"
              className={clsx({
                [styles.toggledImage]: toggledCard === i,
                [styles.notToggledImage]: toggledCard !== i
              })}
            />
          </button>
        );
      })}
      <IconButton
        directory="dls"
        onClick={() => paddleClickHandler(80, 'right')}
        imageName="dls-icon-right"
        aria-label="Scroll right to view more cards"
        aria-hidden={!showRightToggle}
        className={styles.rightPaddle}
        style={{
          visibility: showRightToggle ? 'visible' : 'hidden',
          display: cardList.length > 2 ? 'block' : 'none'
        }}
        $size={Size.MD}
        theme={{
          color: Color.DeepBlue
        }}
      />
    </div>
  );
};

export default CarouselCardTabs;
