import React, { memo } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Size, Modal } from '@americanexpress/aqx-components';
import toolTipStyles from 'src/universal/components/SuppModal/components/styles/toolTipWrapper.scss?modules';
import pageToActionNameMap from './pageToActionNameMap';
import { getActivePageName } from 'src/universal/redux/page';
import { getSuppModalDtmData } from './redux';

const noop = () => {};

const SuppCardsModal = memo(
  ({ showModal = false, modalData = {}, toggle = noop }) => {
    return (
      <div className={toolTipStyles.modal}>
        <Modal.Container $isOpen={showModal} toggle={toggle} $size={Size.MD}>
          <Modal.Title
            customization={{
              containerTheme: {
                paddingTop: 15,
                paddingBottom: 15,
                paddingLeft: 20
              }
            }}
          >
            {modalData.header}
          </Modal.Title>
          <h3 className={toolTipStyles.header}>{modalData.title}</h3>
          <div className={toolTipStyles.contentContainer}>
            <Modal.Content
              theme={{
                paddingLeft: 20,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0
              }}
            >
              <ul className={toolTipStyles.content}>
                {(Array.isArray(modalData.desc) ? modalData.desc : []).map(
                  (content, index) => <li key={index}>{content.text}</li>
                )}
              </ul>
            </Modal.Content>
          </div>
        </Modal.Container>
      </div>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const PagesScope = {
    businessPDP: 'businessPDP.TNL.suppCardsModal',
    businessCardsV2: 'businessCardsV2.TNL.suppCardsModal',
    businessHome: 'businessHome.TNL.suppCardsModal',
    businessHub: 'businessHub.TNL.suppCardsModal',
    cardDetail: 'cardDetail.TNL.suppCardsModal'
  };
  const showSuppCardsModalByPageState = {
    businessPDP: get(state, 'suppModal.suppModalOpen') || false,
    businessCardsV2: get(state, 'businessCardsV2.suppModalOpen') || false
  };
  const currentPage = state.activePageName;

  const isToggled =
    showSuppCardsModalByPageState[currentPage] || ownProps.showSuppCardsModal;

  const page = getActivePageName(state);
  return {
    modalData: get(state, `${PagesScope[currentPage]}`) || {},
    showModal: isToggled,
    dtmPage: pageToActionNameMap[page],
    dtmElement: ownProps.dtmElement || getSuppModalDtmData(state)
  };
};

export default connect(mapStateToProps)(SuppCardsModal);
