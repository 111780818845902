import React from 'react';
import clsx from 'clsx';
import { CardArt } from '@americanexpress/aqx-components';
import styles from './__styles__/cardBox.scss?modules';

const FocusableCardArt = ({ imgUrl, linkUrl, onClick, ariaLabel }) => {
  return (
    <a
      className={styles.cardArtLink}
      href={linkUrl}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <CardArt
        tabIndex="0"
        className={clsx(styles.cardArt, { [styles.isEmpty]: imgUrl === '' })}
        $withShadowHover
        src={imgUrl}
        alt={ariaLabel}
      />
    </a>
  );
};

export default React.memo(FocusableCardArt);
