import React from 'react';
import PropTypes from 'prop-types';
import { Size, FontType, Modal, Typography } from '@americanexpress/aqx-components';
import { Sanitizer } from 'acq-components/lib/base-components/Sanitizer/Sanitizer';

const noop = () => {};

const PDPModal = ({
  showModal = false,
  props = {},
  dynamicClassName,
  toggle = noop,
  flagText
}) => {
  const { subTitle = '', title = '', disclaimer = '' } = props.data || {};
  const disclaimerWithFormattedUrl = disclaimer;
  return (
    <div className={`${dynamicClassName}`}>
      <Modal.Container
        $isOpen={showModal}
        toggle={() => {
          toggle();
        }}
        $size={Size.MD}
      >
        {subTitle && (
          <Modal.Title
            customization={{
              containerTheme: {
                paddingTop: 15,
                paddingBottom: 15,
                paddingLeft: 40
              }
            }}
          >
            {subTitle}
          </Modal.Title>
        )}
        {title && (
          <Modal.Title
            customization={{
              containerTheme: {
                paddingTop: 15,
                paddingBottom: 15,
                paddingLeft: 40
              }
            }}
          >
            {title}
          </Modal.Title>
        )}
        <div className="contentContainer">
          <Modal.Content
            theme={{
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 30,
              paddingBottom: 30
            }}
          >
            {flagText && (
              <Typography
                Component="p"
                $family={FontType.HelveticaRegular}
                $size="15px"
                $lineHeight="22px"
                theme={{
                  $size: 15,
                  color: '#333333',
                  marginBottom: 15
                }}
              >
                <Sanitizer safelySetInnerHTML={flagText} />
              </Typography>
            )}
            {disclaimerWithFormattedUrl && (
              <Typography
                Component="p"
                $family={FontType.HelveticaRegular}
                $size="15px"
                $lineHeight="22px"
                theme={{
                  $size: 15,
                  color: '#333333',
                  marginBottom: 15
                }}
              >
                <Sanitizer safelySetInnerHTML={disclaimerWithFormattedUrl} />
              </Typography>
            )}
          </Modal.Content>
        </div>
      </Modal.Container>
    </div>
  );
};

PDPModal.propTypes = {
  dynamicClassName: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    disclaimer: PropTypes.string
  })
};

PDPModal.defaultProps = {
  dynamicClassName: '',
  flagText: '',
  data: {
    title: '',
    subTitle: '',
    disclaimer: ''
  }
};

export default PDPModal;
