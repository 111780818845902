import React from 'react';
import { connect } from 'react-redux';
import { Size, Icon } from '@americanexpress/aqx-components';
import pageToActionNameMap from '../pageToActionNameMap';
import { getActivePageName } from 'src/universal/redux/page';
import toolTipStyles from 'src/universal/components/SuppModal/components/styles/toolTipWrapper.scss?modules';
import { isGCSMember } from 'src/universal/redux/cardMemberInfo.js';
import { isCardMember } from 'src/universal/redux/userType.js';

const SupplementaryToolTip = ({
  iconSize = Size.MD,
  hideFromScreenreaders = false
}) => {
  return (
    <span className={toolTipStyles.addEmployeeInfo}>
      <Icon
        directory="dls"
        imageName="dls-icon-info-tooltip"
        $size={iconSize}
        hiddenToScreenReaders={hideFromScreenreaders}
      />
    </span>
  );
};

export default connect(state => {
  const page = getActivePageName(state);
  return {
    dtmPage: pageToActionNameMap[page],
    isGCSMember: isGCSMember(state),
    isCardMember: isCardMember(state)
  };
})(SupplementaryToolTip);
