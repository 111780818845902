import React from 'react';
import get from 'lodash.get';
import clsx from 'clsx';
import deepmerge from 'deepmerge';
import removeTagsAndTheirContent from 'service_modules/utils/text-remove-tags';
import { Sanitizer } from 'acq-components/lib/base-components/Sanitizer/Sanitizer';
import SupplementaryToolTip from 'src/universal/components/SuppModal/components';
import {
  Element,
  CardArt,
  Link,
  Button,
  Breakpoint,
  Typography
} from '@americanexpress/aqx-components';
import { defaultTabContentCustomization } from './defaultCustomizations';
import { itemContentType, customizationType } from './types';
import styles from './__styles__/tabControl.scss?modules';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';
import isProduction from 'src/universal/utils/isProduction';

const findLinkByKey = (links, key) => {
  return (links || []).find(l => l.key === key);
};

const findLinkByParticalKey = (links, key) => {
  return (links || []).find(l => String(l.key).endsWith(key));
};

const buildDynamicCta = ({ ctaLink, custObj, breakPoint }) => {
  if ((ctaLink.key || '').indexOf('pznTab') > -1) {
    // this is the case that the data is from pzn.
    return (
      <Button
        Component="a"
        href={ctaLink.url}
        title={removeTagsAndTheirContent(ctaLink.title)}
        theme={get(custObj, `learnMoreCta.themes.${breakPoint}`)}
        onClick={() => {
          sendDTMClickEvent(ctaLink.dtm);
        }}
      >
        <Sanitizer safelySetInnerHTML={ctaLink.text} />
      </Button>
    );
  }

  // we keep the original BAU logic here (for static data)
  return (
    <Button
      onClick={() => {
        if (!isProduction()) {
          console.log(`url: ${ctaLink.url}`);
        }
        window.location.href = ctaLink.url;
      }}
      theme={get(custObj, `learnMoreCta.themes.${breakPoint}`)}
    >
      {ctaLink.title}
    </Button>
  );
};

const TabContent = ({
  content,
  customization,
  index,
  eventHandlers,
  activeIndex
}) => {
  const custObj = deepmerge(defaultTabContentCustomization, customization || {});
  const { cardArtUrl, cardArtAlt, description, headline, links } = content;
  const hasImg = !!cardArtUrl;
  const ctaLink = findLinkByParticalKey(links, 'Cta');
  const toolTipLink = findLinkByParticalKey(links, 'ToolTip');
  const offerTermsLink = findLinkByKey(links, 'offerTerms');
  const rateAndFeesLink = findLinkByKey(links, 'rateAndFees');
  const cardArtLink = findLinkByParticalKey(links, 'CardArt');
  let cmToolTipHandler;
  let ctaTitle;
  if (toolTipLink && String(toolTipLink.key).indexOf('compareCard') > -1) {
    cmToolTipHandler = (eventHandlers || {}).cmCompareCtaToolToggle;
    ctaTitle = ctaLink.title;
  } else {
    cmToolTipHandler = (eventHandlers || {}).cmToolTipToggle;
  }
  const onTooltipClick = e => {
    e.stopPropagation();
    sendDTMClickEvent(toolTipLink.dtm);
    if (typeof cmToolTipHandler === 'function') {
      cmToolTipHandler(ctaTitle);
    }
  };
  const disableHover = hasImg
    ? String(cardArtUrl).indexOf('3cards_1024.svg') > -1
    : false;
  const noTNC = !offerTermsLink && !rateAndFeesLink;
  const renderImg = index === activeIndex;

  return (
    <Breakpoint>
      {({ current }) => {
        return (
          <Element
            className={clsx(
              styles.tabContent,
              styles[`item${index}`],
              {
                [styles.hasNoImg]: !hasImg
              },
              { [styles.disableHover]: disableHover }
            )}
          >
            <Element
              className={styles.left}
              theme={get(custObj, `contentPanel.themes.${current}`)}
            >
              <Typography
                Component={get(custObj, 'tabContentTitle.htmlTag')}
                $size={get(custObj, `tabContentTitle.fontSizes.${current}`)}
                $lineHeight={'130%'}
                theme={get(custObj, `tabContentTitle.themes.${current}`)}
              >
                <Element className={styles.contentTitle} Component="span">
                  {headline}
                </Element>
              </Typography>
              <Typography
                Component={get(custObj, 'tabContentText.htmlTag')}
                $size={get(custObj, `tabContentText.fontSizes.${current}`)}
                $lineHeight={'130%'}
                theme={get(custObj, `tabContentText.themes.${current}`)}
              >
                <Element className={styles.contentText} Component="span">
                  {description}
                </Element>
              </Typography>
              <Element
                className={clsx(
                  styles.ctaRow,
                  {
                    [styles.hasToolTip]: !!toolTipLink
                  },
                  { [styles.noTNC]: noTNC }
                )}
              >
                <Element className={styles.ctaAndTooltipWrap}>
                  {ctaLink &&
                    buildDynamicCta({ ctaLink, custObj, breakPoint: current })}
                  {toolTipLink && (
                    <Element
                      className={styles.ctaToolTip}
                      Component="button"
                      onClick={e => onTooltipClick(e)}
                    >
                      <SupplementaryToolTip dtmElement="tabbedHero" iconSize="md" />
                    </Element>
                  )}
                </Element>
                {!noTNC && (
                  <Element className={styles.tncHolder}>
                    {offerTermsLink && (
                      <Link
                        href={offerTermsLink.url}
                        title={removeTagsAndTheirContent(offerTermsLink.title)}
                        target="_blank"
                        onClick={() => {
                          if (offerTermsLink.dtm) {
                            sendDTMClickEvent(offerTermsLink.dtm);
                          }
                        }}
                      >
                        <Sanitizer safelySetInnerHTML={offerTermsLink.title} />
                      </Link>
                    )}
                    {rateAndFeesLink && (
                      <Link
                        href={rateAndFeesLink.url}
                        title={removeTagsAndTheirContent(rateAndFeesLink.title)}
                        target="_blank"
                        onClick={() => {
                          if (rateAndFeesLink.dtm) {
                            sendDTMClickEvent(rateAndFeesLink.dtm);
                          }
                        }}
                      >
                        <Sanitizer safelySetInnerHTML={rateAndFeesLink.title} />
                      </Link>
                    )}
                  </Element>
                )}
              </Element>
            </Element>
            {hasImg &&
              renderImg && (
                <CardArt
                  $withShadowHover
                  className={styles.right}
                  src={cardArtUrl}
                  alt={cardArtAlt}
                  onClick={() => {
                    if (cardArtLink) {
                      if (!isProduction()) {
                        console.log(`url: ${cardArtLink.url}`);
                      }
                      window.location.href = cardArtLink.url;
                    }
                  }}
                />
              )}
          </Element>
        );
      }}
    </Breakpoint>
  );
};

TabContent.propTypes = {
  content: itemContentType,
  customization: customizationType
};

TabContent.defaultProps = {
  customization: {}
};

export default React.memo(TabContent);
