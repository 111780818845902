import React from 'react';
import PropTypes from 'prop-types';
import { Element } from '@americanexpress/aqx-components';
import TabControl from './TabControl';
import styles from './__styles__/index.scss?modules';
import { getContentGroup, needToRenderCardBox } from './utils/index.js';
import UserCardBox from './UserCardBox';
import { heroDataType } from './types';

/* eslint-disable no-unused-vars */
const Mobile = ({
  data,
  isGCS,
  userCardsInfo,
  selectedCard,
  cmTabbedHeroData,
  ...otherProps
}) => {
  const needUserCardBox = needToRenderCardBox(isGCS, userCardsInfo);
  const contentGrp = getContentGroup(data, selectedCard) || {};
  const userCardBoxData = data?.userCardBoxData;
  const cardBoxProps = {
    userCardsInfo,
    selectedCard,
    userCardBoxData,
    isMobile: true,
    layoutStyles: styles
  };

  return (
    <Element className={styles.mobileWrap}>
      {needUserCardBox && <UserCardBox {...cardBoxProps} />}
      <TabControl contentList={contentGrp.content} {...otherProps} />
    </Element>
  );
};

Mobile.propTypes = {
  data: PropTypes.shape({
    heroData: heroDataType
  }),
  isGCS: PropTypes.bool,
  userCardsInfo: PropTypes.object
};

Mobile.defaultProps = {
  data: {}
};

export default React.memo(Mobile);
