import PropTypes from 'prop-types';

export const itemContentType = PropTypes.shape({
  cardArtUrl: PropTypes.string,
  cardArtAlt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  headline: PropTypes.string,
  dtmEvents: PropTypes.arrayOf(PropTypes.object),
  links: PropTypes.arrayOf(PropTypes.object)
});

export const heroContentType = PropTypes.shape({
  key: PropTypes.string,
  heroTitle: PropTypes.string,
  content: PropTypes.arrayOf(itemContentType)
});

export const heroDataType = PropTypes.shape({
  backgroundImage: PropTypes.string,
  header: PropTypes.string,
  heroContent: PropTypes.arrayOf(heroContentType)
});

export const fontsType = PropTypes.shape({
  sm: PropTypes.any,
  md: PropTypes.any,
  lg: PropTypes.any
});

export const fontSizesType = PropTypes.shape({
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number
});

export const themesType = PropTypes.shape({
  sm: PropTypes.any,
  md: PropTypes.any,
  lg: PropTypes.any
});

export const custItemType = PropTypes.shape({
  htmlTag: PropTypes.string,
  fontSizes: fontSizesType,
  themes: themesType,
  fontTypes: fontsType
});

export const customizationType = PropTypes.shape({
  heroTitle1: custItemType,
  heroTitle2: custItemType,
  tabContentTitle: custItemType,
  tabContentText: custItemType,
  userCardSectionTitle: custItemType,
  userCardTitle: custItemType
});
