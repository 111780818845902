import React from 'react';
import clsx from 'clsx';
import {
  Element,
  Color,
  FontType,
  Link,
  Typography
} from '@americanexpress/aqx-components';
import { Sanitizer } from 'acq-components/lib/base-components/Sanitizer/Sanitizer';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';
import FocusableCardArt from './FocusableCardArt';
import CarouselCardTabs from './CarouselCardTabs';
import removeTagsAndTheirContent from 'service_modules/utils/text-remove-tags.js';
import LazyScrollMetricTracker from 'src/universal/components/DTM/LazyScrollMetricTracker';
import styles from './__styles__/cardBox.scss?modules';
import {
  mapUrlWithDtmLinkQuery,
  sendDTMClickEvent
} from 'service_modules/models/dtm-tagging';

const UserCardBox = ({
  userCardsInfo,
  selectedCard,
  userCardBoxData,
  isMobile,
  layoutStyles
}) => {
  const titleLinkObj = (userCardBoxData?.links || []).find(
    l => l.key === 'titleUnderCardArt'
  );
  const rawTitle = titleLinkObj?.title || '';
  const cardTitle = replaceStringWithVars({
    varsObject: { cardName: selectedCard?.title },
    string: rawTitle
  });

  let cardArtHref = (selectedCard?.url || '').split('?')[0];
  const cardArtLinkObj = (userCardBoxData?.links || []).find(
    l => l.key === 'cardArt'
  );
  const titleDtm = {
    ...(titleLinkObj?.dtm || {}),
    tag: replaceStringWithVars({
      varsObject: { product: selectedCard?.shortName },
      string: titleLinkObj?.dtm?.tag
    }),
    eventAsset: replaceStringWithVars({
      varsObject: { product: selectedCard?.shortName },
      string: titleLinkObj?.dtm?.eventAsset
    })
  };
  const cardArtDtm = {
    ...(cardArtLinkObj?.dtm || {}),
    tag: replaceStringWithVars({
      varsObject: { product: selectedCard?.shortName },
      string: cardArtLinkObj?.dtm?.tag
    }),
    eventAsset: replaceStringWithVars({
      varsObject: { product: selectedCard?.shortName },
      string: cardArtLinkObj?.dtm?.eventAsset
    })
  };
  const linkImpressionDtm = (userCardBoxData?.dtmEvents || [])[0] || {};

  const cardArtProps = {
    imgUrl: selectedCard?.src,
    linkUrl: mapUrlWithDtmLinkQuery(cardArtHref, cardArtDtm),
    onClick: () => {
      sendDTMClickEvent(cardArtDtm);
    },
    ariaLabel: removeTagsAndTheirContent(cardTitle)
  };

  const cardListProps = {
    cardList: userCardsInfo?.cardHeroData || [],
    dtm: userCardBoxData?.dtmEvents || [],
    cardClick: userCardsInfo?.onCardClick || (() => {}),
    activeIndex: selectedCard.index
  };

  const cardCnt = (userCardsInfo?.cardHeroData || []).length;
  const hasLessThan3 = cardCnt < 3;

  return (
    <Element
      className={clsx(
        layoutStyles?.userCardBox,
        styles.cardBoxWrap,
        {
          [styles.hasLessThan3]: hasLessThan3
        },
        styles[`cardCnt${cardCnt}`]
      )}
    >
      <Typography
        className={styles.cmGreeting}
        Component="h3"
        $family={FontType.HelveticaRegular}
        $size={20}
        $lineHeight={'130%'}
        theme={{ color: Color.DeepBlue }}
      >
        {userCardBoxData?.heroTitle2}
      </Typography>
      {!isMobile && <FocusableCardArt {...cardArtProps} />}
      {isMobile && <CarouselCardTabs {...cardListProps} />}
      <LazyScrollMetricTracker scrollDtm={linkImpressionDtm} />
      <Link
        className={styles.cardTitleLink}
        href={mapUrlWithDtmLinkQuery(cardArtHref, titleDtm)}
        onClick={() => {
          sendDTMClickEvent(titleDtm);
        }}
      >
        <Sanitizer safelySetInnerHTML={cardTitle} />
      </Link>
      {!isMobile && cardCnt > 1 && <CarouselCardTabs {...cardListProps} />}
    </Element>
  );
};

export default React.memo(UserCardBox);
