import React, { useEffect } from 'react';
import get from 'lodash.get';
import {
  getImpressionDtmForHero,
  getImpressionDtmForCardBox,
  needToRenderCardBox,
  getCmExtItem
} from 'src/universal/components/CmTabbedHero/utils';
import { sendDTMCustomEvent } from 'service_modules/models/dtm-tagging';
import DesktopAndTablet from './DesktopAndTablet';
import { cmComponentPznExt } from 'src/universal/utils/orchestraCmPznExtEvent';
import Mobile from './Mobile';

let doneInitLoadImpression = false;
let doneCardBoxImpression = false;

const HeroMain = ({ isMobile, sharedProps }) => {
  useEffect(() => {
    const heroData = sharedProps.data || {};
    if (doneInitLoadImpression !== true) {
      doneInitLoadImpression = true;
      const { selectedCard = {} } = sharedProps;
      cmComponentPznExt([getCmExtItem(heroData, selectedCard, 0)]);
      setTimeout(() => {
        const cardHeroData = get(sharedProps, 'userCardsInfo.cardHeroData') || {};
        const heroImpression = getImpressionDtmForHero(
          cardHeroData,
          heroData,
          selectedCard
        );
        sendDTMCustomEvent(heroImpression);
      }, 15);
    }

    const needCardBox = needToRenderCardBox(true, sharedProps.userCardsInfo);
    if (doneCardBoxImpression !== true && needCardBox) {
      doneCardBoxImpression = true;
      setTimeout(() => {
        const cardNames = get(sharedProps, 'userCardsInfo.cardNames') || [];
        const cardBoxImpression = getImpressionDtmForCardBox(
          cardNames,
          heroData.dtmHash
        );
        sendDTMCustomEvent(cardBoxImpression);
      }, 30);
    }

    setTimeout(() => {
      const cardList = sharedProps?.userCardsInfo?.cardHeroData;
      const refreshFunc = sharedProps?.userCardsInfo?.refreshCmSelected;
      if (Array.isArray(cardList) && typeof refreshFunc === 'function') {
        refreshFunc({ ...cardList[0], index: 0 });
      }
    }, 800);
  }, []);

  return isMobile ? (
    <Mobile {...sharedProps} />
  ) : (
    <DesktopAndTablet {...sharedProps} />
  );
};

export default React.memo(HeroMain);
