/*
GACC-8549: CmTabbedHero to here (src/components/) and other comment?
(1) The CardMember feature (GACC-8549) requires the complete implementation of original G90.
(2) At this stage, this component is ONLY used for card-member use-case. 
(3) The prospect users are currently using "SummitTabbedHero" which has significantly diversed from this one.
(4) This Slack thread covers the g103/g90 test status and the neccessity of keeing 2 versions of TabbeHeros:
      https://aexp-mobile-and-web.slack.com/archives/G4XRGE072/p1639677355415900
*/

import React, { useState, useRef } from 'react';
import get from 'lodash.get';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepmerge from 'deepmerge';
import {
  getMemberCardShortNames,
  getCMTabbedHeroTitle,
  isGCSMember,
  hasMemberInfoArrived
} from 'src/universal/redux/cardMemberInfo';
import { hasPznDataArrived } from 'src/universal/redux/pznData';
import { isProspect, isLoggedOut } from 'src/universal/redux/userType';
import ProgressCircle from 'core_modules/ajax-loaders/templates/progress-circle/progress-circle';
import { Element, Breakpoint, Typography } from '@americanexpress/aqx-components';
import SimpleMsgModal from 'src/universal/components/Modal/Variations/PDPModal';
import defaultStyles from './__styles__/index.scss?modules';
import { getHeroData, getCMTabbedHeroCardList } from './redux';
import {
  getImpressionDtmForHero,
  getDtmForUserCardClick,
  getDtmForCardBoxNavigate,
  getCmExtItem
} from 'src/universal/components/CmTabbedHero/utils';
import {
  sendDTMCustomEvent,
  sendDTMClickEvent
} from 'service_modules/models/dtm-tagging';
import { cmComponentPznExt } from 'src/universal/utils/orchestraCmPznExtEvent';
import HeroMain from './HeroMain';
import { defaultHeroCustomization } from './defaultCustomizations';
import { customizationType, heroDataType } from './types';
import {
  getContentGroup,
  needToRenderCardBox,
  isCardHighlightRequired
} from './utils';
import { getWinWidth } from 'core_modules/bootstrap/browsers/browser-detection';
import { getPageData } from 'src/universal/redux/page';

const TabbedHero = ({
  heroData,
  isGCS,
  cardHeroData,
  needCardHighlight,
  cardNames,
  customization,
  cardMemberHeroTitle,
  groupTitle,
  hasCmDataArrived,
  hasPznArrived,
  isUserProspect,
  cmToolTipToggle,
  customStyles,
  qeId,
  isBusinessHub
}) => {
  const initialCard = {
    ...(cardHeroData.length > 0 ? cardHeroData[0] : {}),
    index: 0
  };
  const [selectedCard, setSelectedCard] = useState(initialCard);
  const [showCompareTooltipModal, setShowCompareTooltipModal] = useState(false);
  const currTabIndex = useRef(0);
  const compareTooltipModalTitle = useRef('');
  const cardIaCodesMadeImpression = useRef([initialCard.iaCode || 'none']);

  const tabClickHandler = selectedIndex => {
    currTabIndex.current = selectedIndex;
    const cmExtObj = getCmExtItem(heroData, selectedCard, selectedIndex);
    if (cmExtObj) {
      cmComponentPznExt([cmExtObj]);
    }
  };

  const toggleCompareCtaTooltipModal = ctaTitle => {
    compareTooltipModalTitle.current = ctaTitle;
    setShowCompareTooltipModal(!showCompareTooltipModal);
  };

  const compareCtaTipModalProps = {
    showModal: showCompareTooltipModal,
    toggle: toggleCompareCtaTooltipModal,
    props: {
      data: {
        title: compareTooltipModalTitle.current,
        disclaimer: get(heroData, 'heroData.labels[0].text')
      }
    }
  };

  const cardClickHandler = (index, cardObj, custDtm) => {
    setSelectedCard({ ...cardObj, index });
    sendDTMClickEvent(
      custDtm
        ? custDtm
        : getDtmForUserCardClick(heroData.dtmHash, selectedCard, cardObj)
    );
    setTimeout(() => {
      // US3495698: we need to trigger cm-pzn-ext call
      const cmExtItem = getCmExtItem(heroData, cardObj, currTabIndex.current);
      if (cmExtItem) {
        cmComponentPznExt([cmExtItem]);
      }

      // US3159446: hero-level impression must fire for every user-cards.
      const cardsImpressed = cardIaCodesMadeImpression.current || [];
      if (cardsImpressed.indexOf(cardObj.iaCode) <= -1) {
        cardsImpressed.push(cardObj.iaCode);
        const heroImpression = getImpressionDtmForHero(
          cardHeroData,
          heroData,
          cardObj
        );
        sendDTMCustomEvent(heroImpression);
      }
    }, 50);
  };

  const cardBoxNavigateHandler = isForward => {
    sendDTMClickEvent(
      getDtmForCardBoxNavigate(heroData.dtmHash, selectedCard, isForward)
    );
  };

  const styles = customStyles || defaultStyles;
  const custObj = deepmerge(defaultHeroCustomization, customization || {});
  const heroTitle1 = get(heroData, 'heroData.header') || '';
  const heroTitle2 = isGCS ? cardMemberHeroTitle : groupTitle;

  if (heroData?.userCardBoxData) {
    heroData.userCardBoxData.heroTitle2 = heroTitle2;
  }

  const sharedProps = {
    data: heroData,
    isGCS,
    userCardsInfo: {
      cardNames,
      cardHeroData,
      cardMemberHeroTitle,
      onCardClick: cardClickHandler,
      onCardNavigate: cardBoxNavigateHandler,
      refreshCmSelected: cardObj => {
        setSelectedCard(cardObj);
      }
    },
    onTabClick: tabClickHandler,
    eventHandlers: {
      cmToolTipToggle,
      cmCompareCtaToolToggle: toggleCompareCtaTooltipModal
    },
    labels: heroData.labels,
    selectedCard
  };

  let needTitle2 = true;

  if (hasPznArrived && hasCmDataArrived) {
    needTitle2 = false;
  }

  const getBackImgUrl = isMobile => {
    if (hasPznArrived && hasCmDataArrived) {
      return isMobile
        ? heroData?.userCardBoxData?.imgUrlMobile
        : heroData?.userCardBoxData?.imgUrlDesktop;
    }
    return '';
  };

  if (!isUserProspect && (!hasCmDataArrived || !hasPznArrived)) {
    return (
      <Element className={clsx(styles.tabbedHeroWrap, styles.spinner)}>
        <Element
          className={clsx(styles.backImgHolder, { [styles.isGCS]: isGCS })}
          style={{ backgroundImage: `url(${getBackImgUrl(getWinWidth() <= 767)})` }}
        />
        <ProgressCircle />
      </Element>
    );
  }

  const needCardBox = needToRenderCardBox(isGCS, sharedProps.userCardsInfo);

  return (
    <Breakpoint>
      {({ current, sm }) => {
        return (
          <Element
            className={clsx(
              styles.tabbedHeroWrap,
              { heroHasCardBox: needCardBox },
              { heroNoCardBox: !needCardBox },
              styles[`cardIndex${selectedCard ? selectedCard.index : ''}`],
              { [styles.needCardHighlight]: needCardHighlight },
              { [styles.hasUserCardBox]: !!heroData?.userCardBoxData }
            )}
            qeId={qeId}
          >
            <SimpleMsgModal {...compareCtaTipModalProps} />
            {isBusinessHub && (
              <Element
                className={clsx(styles.backImgHolder, {
                  [styles.isGCS]: isGCS
                })}
                style={{ backgroundImage: `url(${getBackImgUrl(sm)})` }}
              />
            )}
            <Element className={styles.innerWrap}>
              <Typography
                Component={get(custObj, 'heroTitle1.htmlTag')}
                $size={get(custObj, `heroTitle1.fontSizes.${current}`)}
                $lineHeight={'130%'}
                theme={{
                  ...get(custObj, `heroTitle1.themes.${current}`),
                  marginBottom: 5
                }}
              >
                <Element className={styles.heroTitle1} Component="span">
                  {heroTitle1}
                </Element>
              </Typography>
              {needTitle2 && (
                <Typography
                  Component={custObj.heroTitle2.htmlTag}
                  $size={get(custObj, `heroTitle2.fontSizes.${current}`)}
                  $lineHeight={'130%'}
                  theme={get(custObj, `heroTitle2.themes.${current}`)}
                >
                  <Element className={styles.heroTitle2} Component="span">
                    {heroTitle2}
                  </Element>
                </Typography>
              )}
              <HeroMain isMobile={sm} sharedProps={sharedProps} />
            </Element>
          </Element>
        );
      }}
    </Breakpoint>
  );
};

const mapStateToProps = state => {
  const initialEEP = getPageData(state)?.initialEEP;
  const isGCSVal = isGCSMember(state);
  const heroData = getHeroData(initialEEP)(state);
  const group = getContentGroup(heroData, null);
  const groupTitle = (group || {}).heroTitle;
  const isUserLoggedOut = isLoggedOut(state);
  const cardHeroData = getCMTabbedHeroCardList(state);
  return {
    heroData,
    cardHeroData,
    needCardHighlight: isCardHighlightRequired(heroData, cardHeroData),
    cardNames: getMemberCardShortNames(state),
    cardMemberHeroTitle: getCMTabbedHeroTitle(groupTitle, isUserLoggedOut)(state),
    isGCS: isGCSVal,
    groupTitle,
    hasCmDataArrived: hasMemberInfoArrived(state),
    hasPznArrived: hasPznDataArrived(state),
    isUserProspect: isProspect(state)
  };
};

TabbedHero.propTypes = {
  heroData: PropTypes.shape({
    heroData: heroDataType
  }),
  isGCS: PropTypes.bool,
  cardHeroData: PropTypes.arrayOf(PropTypes.object),
  cardNames: PropTypes.arrayOf(PropTypes.string),
  cardMemberHeroTitle: PropTypes.string,
  groupTitle: PropTypes.string,
  customization: customizationType,
  qeId: PropTypes.string
};

TabbedHero.defaultProps = {
  qeId: 'TabbedHero'
};

export default connect(mapStateToProps)(React.memo(TabbedHero));
