import get from 'lodash.get';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';
import convertToAsset from 'src/universal/utils/titleToDtmAsset';
import { buildUpdatedCtaUrl } from 'src/universal/utils/orchestraUtils';
import { getFormattedLinks } from 'src/universal/utils/getFormattedLinks';
// priviate methods

const buildDynamicDtm = ({ dtmHash = {}, key = '', dynamicVal = '' }) => {
  const dtmObj = { ...(dtmHash[key] || {}) };
  dtmObj.eventAsset = replaceStringWithVars({
    varsObject: {
      dynamicAsset: dynamicVal
    },
    string: dtmObj.eventAsset
  });
  return dtmObj;
};

const getTooltipLinkObj = (staticHeroData, key) => {
  const contentList = get(staticHeroData, 'heroContent[0].content') || [];
  let resultObj = null;

  // using for-loop so we can exit it early.
  for (let i = 0; i < contentList.length; i++) {
    const linkObj = (contentList[i].links || []).find(link => {
      return link.key === key;
    });
    if (linkObj) {
      resultObj = { ...linkObj };
      break; // we break off the loop
    }
  }
  return resultObj;
};

const sortPznHeroData = (pznHeroData = []) => {
  const listCopy = [...pznHeroData];
  listCopy.sort((a, b) => {
    if (a.displayRank > b.displayRank) {
      return 1;
    }
    if (a.displayRank < b.displayRank) {
      return -1;
    }
    return 0;
  });
  return listCopy;
};

export const extractUniqueIaCodes = (pznHeroData = []) => {
  return pznHeroData.reduce((arr, item) => {
    const iaCode = item.cardObjIaCode;
    if (arr.indexOf(iaCode) <= -1) {
      arr.push(iaCode);
    }
    return arr;
  }, []);
};

const validateTxt = txt => typeof txt === 'string' && txt.trim().length > 0;

const isTabContentValid = (tabItem = {}) => {
  // rules are in this story: US3469551
  const validCta = (tabItem.links || []).find(l => {
    return validateTxt(l.title) && validateTxt(l.url);
  });
  return (
    validateTxt(tabItem.title) &&
    validateTxt(tabItem.header) &&
    validateTxt(tabItem.description) &&
    !!validCta
  );
};

export const processTabData = (pznHeroData = [], iaCode, maxTabs = 3) => {
  // (a) original filter-by-iaCode (b) content validity (US3469551)
  const filteredList = pznHeroData.filter(item => {
    return item.cardObjIaCode === iaCode && isTabContentValid(item);
  });

  const sortedList = sortPznHeroData(filteredList);

  if (sortedList.length > maxTabs) {
    return sortedList.slice(0, maxTabs);
  }

  return sortedList;
};

// public methods:

export const getContentGroup = (data, selectedCard) => {
  const dataLists = get(data, 'heroData.heroContent') || [];
  if (selectedCard) {
    const result = dataLists.find(item => {
      return item.iaCode === selectedCard.iaCode;
    });
    return result || dataLists[0] || {};
  }
  return dataLists[0] || {};
};

export const buildStaticHeroData = (heroData, pageEep, eepHash) => {
  const contentResult = [0, 1].map(i => {
    const key = get(heroData, `heroContent[${i}].key`) || '';
    const heroTitle = get(heroData, `heroContent[${i}].heroTitle`) || '';
    const items = get(heroData, `heroContent[${i}].content`) || [];
    return {
      key,
      heroTitle,
      content: items.map(item => {
        return {
          ...item,
          links: getFormattedLinks(item.links, pageEep, eepHash)
        };
      })
    };
  });
  return {
    ...heroData,
    heroContent: contentResult
  };
};

export const needToRenderCardBox = (isGCS, userCardsInfo) => {
  const cardList = (userCardsInfo || {}).cardHeroData || [];
  return isGCS && Array.isArray(cardList) && cardList.length > 0;
};

export const buildPznHeroData = (heroData, pznHeroData, pgLabels, dtmHash = {}) => {
  if (!heroData) {
    return {};
  }
  const groupKeys = extractUniqueIaCodes(pznHeroData);
  const resultList = groupKeys.map(iaCodeKey => {
    const sortedList = processTabData(
      pznHeroData,
      iaCodeKey,
      heroData.maxTabsAllowed
    );
    const staticContent = get(heroData, 'heroContent[0]') || {};
    const resultItem = { ...staticContent };
    const buildDtmEvents = (idx, tabTitle) => {
      const dtmObj = {
        eventAction: 'Click',
        eventType: 'pageInteraction',
        eventAsset: `TH-Tab${idx + 1}-${convertToAsset(tabTitle)}`
      };
      return [dtmObj];
    };
    resultItem.iaCode = iaCodeKey;
    resultItem.content = sortedList.map((item, index) => {
      let hasAddEmpCardsCta = false;
      let hasCompareCardsCta = false;
      const ctaList = (item.links || []).map((linkItem, i) => {
        if (String(linkItem.text).toLowerCase() === 'add employee cards') {
          hasAddEmpCardsCta = true;
        }

        // US5011062: we are to remove attached tooltip from 2 tabs.
        const tabTitle = String(item.title).toLowerCase();
        if (
          String(linkItem.text).toLowerCase() === 'compare cards' &&
          tabTitle.indexOf('travel cards') <= -1 &&
          tabTitle.indexOf('no annual fee') <= -1
        ) {
          hasCompareCardsCta = true;
        }

        return {
          key: `pznTab${index}_link${i}_Cta`,
          ...linkItem,
          url: buildUpdatedCtaUrl(item.clickTrackingUrl, linkItem, true),
          dtm: buildDynamicDtm({
            dtmHash,
            key: 'cmHeroCtaClick',
            dynamicVal: `${convertToAsset(linkItem.text)}-${item.offerId}`
          })
        };
      });

      // per Kiersten, we need to supply the content and behavior for the toolTip info button.
      if (hasAddEmpCardsCta || hasCompareCardsCta) {
        const keyVal = hasAddEmpCardsCta
          ? 'addEmployeeCardToolTip'
          : 'compareCardToolTip';
        const toolTipInfo = getTooltipLinkObj(heroData, keyVal);
        if (toolTipInfo) {
          ctaList.push(toolTipInfo);
        }
      }

      ['offerTerms', 'ratesFees'].forEach(tncKey => {
        const tncObj = get(item, `tncLinks.${tncKey}`) || null;
        if (tncObj && tncObj.url) {
          ctaList.push({
            key: tncKey === 'ratesFees' ? 'rateAndFees' : tncKey,
            ...tncObj,
            title: pgLabels[tncKey],
            dtm: buildDynamicDtm({
              dtmHash,
              key: `cmHeroTnC_${tncKey}`,
              dynamicVal: item.offerId
            })
          });
        }
      });

      return {
        title: item.title,
        headline: item.header,
        description: item.description,
        offerId: item.offerId,
        treatmentId: item.treatmentId,
        componentId: item.componentId,
        dtmEvents: buildDtmEvents(index, item.title),
        links: ctaList
      };
    });

    return resultItem;
  });

  return {
    ...heroData,
    heroContent: resultList
  };
};

export const isCardHighlightRequired = (heroData, cardHeroData) => {
  const dataLists = get(heroData, 'heroData.heroContent') || [];

  if (!Array.isArray(dataLists) || heroData.length <= 1) {
    return false;
  }

  if (!Array.isArray(cardHeroData) || cardHeroData.length <= 0) {
    return false;
  }

  const matchedIaCodes = dataLists.reduce((arr, item) => {
    const matchedCard = cardHeroData.find(c => c.iaCode === item.iaCode);
    if (matchedCard) {
      arr.push(item.iaCode);
    }
    return arr;
  }, []);

  return matchedIaCodes.length > 0 ? true : false;
};

export const getImpressionDtmForHero = (cardHeroData, heroData, selectedCard) => {
  const dynamicAssetList = [];

  if (selectedCard) {
    dynamicAssetList.push(selectedCard.shortName);
  }

  const selectedIaCode = selectedCard ? selectedCard.iaCode : '';
  const contentSet = (get(heroData, 'heroData.heroContent') || []).find(item => {
    return item.iaCode === selectedIaCode;
  });

  if (contentSet) {
    (contentSet.content || []).forEach((tabItem, index) => {
      dynamicAssetList.push(`TH${index + 1}`);
      dynamicAssetList.push(tabItem.offerId || 'no_offer');
    });
  }

  return buildDynamicDtm({
    dtmHash: heroData.dtmHash,
    key: 'cmHeroImpression',
    dynamicVal: dynamicAssetList.join('-')
  });
};

export const getImpressionDtmForCardBox = (cardNames = [], dtmHash = {}) => {
  const dynamicAssetList = [`${cardNames.length}`];
  dynamicAssetList.push(cardNames.join('_'));
  return buildDynamicDtm({
    dtmHash,
    key: 'cmHeroUserCardBoxImpression',
    dynamicVal: dynamicAssetList.join('-')
  });
};

export const getDtmForUserCardClick = (dtmHash, currCard = {}, nextCard = {}) => {
  return buildDynamicDtm({
    dtmHash,
    key: 'cmHeroUserCardClick',
    dynamicVal: `${currCard.shortName}-${nextCard.shortName}`
  });
};

export const getDtmForCardBoxNavigate = (dtmHash, currCard = {}, isForward) => {
  const directionVal = isForward === true ? 'Down' : 'Up';
  return buildDynamicDtm({
    dtmHash,
    key: 'cmHeroUserCardBoxNavigation',
    dynamicVal: `${currCard.shortName}-${directionVal}`
  });
};

export const getCmExtItem = (heroData = {}, selectedCard, tabIndex = 0) => {
  const iaCode = (selectedCard || {}).iaCode;
  const dataSet = (get(heroData, 'heroData.heroContent') || []).find(content => {
    return content.iaCode === iaCode;
  });
  if (dataSet) {
    const treatmentId = get(dataSet, `content[${tabIndex}].treatmentId`);
    const componentId = get(dataSet, `content[${tabIndex}].componentId`);
    return treatmentId
      ? { id: treatmentId, compId: componentId, iaCode }
      : undefined;
  }
  return undefined;
};
