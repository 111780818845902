import get from 'lodash.get';
import cloneDeep from 'clone-deep';
import { createSelector } from 'reselect';
import {
  getPageEep,
  getPageData,
  getPageLabels,
  getPageLevelDtmHash
} from 'src/universal/redux/page';
import {
  hasPznDataArrived,
  getPznPageData,
  getPznOffersEepByShortName
} from 'src/universal/redux/pznData';
import {
  getActivePageName,
  getPageFeatureFlags,
  getRubiksContentList
} from 'src/universal/redux/page';
import { buildStaticHeroData, buildPznHeroData } from '../utils/index.js';
import { getCardMemberCards } from 'src/universal/redux/cardMemberInfo';
import { getCardColorInfoList } from 'src/universal/redux/cardColors.js';
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';

/* eslint-disable max-params */
export const getHeroData = eep =>
  createSelector(
    [
      hasPznDataArrived,
      getActivePageName,
      getPageData,
      getPznPageData,
      getPageEep(eep),
      getPznOffersEepByShortName,
      getPageLabels,
      getPageLevelDtmHash,
      getPageFeatureFlags,
      getCardMemberCards
    ],
    (
      hasPznArrived,
      pageName,
      pageData,
      pznPageData,
      pageEep,
      eepHash,
      pgLabels,
      dtmHash,
      featureFlags,
      userCardList
    ) => {
      let heroDataObj = {};
      let useStaticData = true;
      const allowCmPznData = !!(featureFlags || {}).showCmPznDrivenTabbedHero;
      if (hasPznArrived) {
        const pznHeroData =
          pageName === 'businessHub' ? pznPageData.cmB2B_Hero : pznPageData.cmHero;
        if (
          !allowCmPznData ||
          !pznHeroData ||
          !Array.isArray(pznHeroData) ||
          pznHeroData.length <= 0
        ) {
          heroDataObj = buildStaticHeroData(pageData.tabbedHero, pageEep, eepHash);
        } else {
          useStaticData = false;
          heroDataObj = buildPznHeroData(
            pageData.tabbedHero,
            pznHeroData,
            pgLabels,
            dtmHash
          );

          // DE489808: fill up missing hero content for any card with the static one.
          const contentList = heroDataObj.heroContent || [];
          const iaListMissingData = (userCardList || []).reduce(
            (accumulator, card) => {
              if (!contentList.find(item => item.iaCode === card.iaCode)) {
                accumulator.push(card.iaCode);
              }
              return accumulator;
            },
            []
          );

          if (iaListMissingData.length > 0) {
            const staticHeroData = buildStaticHeroData(
              pageData.tabbedHero,
              pageEep,
              eepHash
            );
            iaListMissingData.forEach(iaCode => {
              const staticCopy = cloneDeep(
                get(staticHeroData, 'heroContent[0]') || {}
              );
              contentList.push({
                ...staticCopy,
                iaCode
              });
            });
          }
        }
      }

      const userCardBox = pageData?.tabbedHero?.userCardBox || {};
      const cbAssets = userCardBox.assets || [];
      const imgUrlDesktop = (
        cbAssets.find(asset => asset.breakpoint === 'desktop') || {}
      ).url;
      const imgUrlMobile = (
        cbAssets.find(asset => asset.breakpoint === 'mobile') || {}
      ).url;

      const userCardBoxData = {
        imgUrlDesktop,
        imgUrlMobile,
        links: userCardBox.links,
        dtmEvents: userCardBox.dtmEvents
      };

      return {
        heroData: heroDataObj,
        labels: pageData.labels,
        useStaticData,
        dtmHash,
        userCardBoxData
      };
    }
  );

export const getCMTabbedHeroCardList = createSelector(
  [
    getCardMemberCards,
    getCardColorInfoList,
    hasPznDataArrived,
    getRubiksContentList
  ],
  (cardsData, colorInfoList, pznArrived, rubiksList) => {
    const buildUrl = card => {
      const url = card.learnMore?.url;
      const dtmObj = {
        type: card.learnMore?.dtmTag?.type,
        tag: `TH-YBC-${card.learnMore?.dtmTag?.productName}`
      };
      return mapUrlWithDtmLinkQuery(url, dtmObj);
    };
    const isOnRubiksList = card => {
      return !!rubiksList.find(item => item.shortName === card.shortName);
    };
    const getCardArtSrc = card => {
      const defaultSrc = card?.appAssets?.cardArt || '';
      const matchedCard = colorInfoList.find(c => c.shortName === card.shortName);
      if (!matchedCard && !pznArrived && isOnRubiksList(card)) {
        return '';
      }
      if (matchedCard && card.digitalAssetId) {
        const rubiksList = matchedCard.rubiksContent?.content || [];
        const matchedAsset = rubiksList.find(
          r => r.digitalAssetId === card.digitalAssetId
        );
        if (matchedAsset) {
          return matchedAsset.appAssets?.cardArt || defaultSrc;
        }
      }
      return defaultSrc;
    };
    const cardsList = cardsData.map(card => ({
      src: getCardArtSrc(card),
      url: buildUrl(card),
      alt: card.cardTitle,
      title: card.cardTitle,
      iaCode: card.iaCode,
      shortName: card.shortName
    }));
    return cardsList;
  }
);
