import React from 'react';
import { Icon } from 'acq-components/lib/base-components/Icon/Icon';
import { Typography } from 'acq-components/lib/base-components/Typography/Typography';
import { Button } from 'acq-components/lib/base-components/Button/Button';
import { MatchHeight } from '@americanexpress/aqx-components';

const BannerSide = ({
  Component,
  className,
  titleProps,
  textProps,
  buttonProps,
  iconProps,
  namespace,
  qeId
}) => {
  return (
    <Component className={className}>
      {iconProps && <Icon {...iconProps} qeId={`${qeId}-icon`} />}
      <MatchHeight name="titlePart">
        <Typography {...titleProps} qeId={`${qeId}-title`} />
      </MatchHeight>
      <MatchHeight name="descPart">
        <Typography {...textProps} qeId={`${qeId}-text`} />
      </MatchHeight>
      <Button {...buttonProps} namespace={namespace} qeId={`${qeId}-button`} />
    </Component>
  );
};

export default React.memo(BannerSide);
